import React from "react";
import * as HeroIcons from "@heroicons/react/outline";
import { TitleHeading } from "../typography/TitleHeading";
import Keyword from "../typography/Keyword";
import Section from "../layout/Section";
import BodyText from "../typography/BodyText";
import { useAnimation } from "../../hooks/useAnimate";

interface FeaturesGridProps {
  tag: string;
  title: string;
  description?: string | React.ReactNode;
  theme?: "white" | "gray";
  elements: {
    icon: string;
    name: string;
    description: string | React.ReactNode;
    child?: React.ReactNode;
  }[];
}

export const FeaturesGrid: React.FunctionComponent<FeaturesGridProps> = ({
  tag,
  title,
  elements,
  description,
  theme,
}) => {
  const { id, animate } = useAnimation();

  return (
    <Section theme={theme} className={`relative space-y-16`} id={id}>
      {(title || tag) && (
        <div {...animate("fade-down")}>
          <TitleHeading
            tag={tag}
            title={title}
            center
            theme={theme}
            bgTag={true}
          />
          {description && (
            <BodyText center justify={false} className="max-w-3xl mx-auto">
              {description}
            </BodyText>
          )}
        </div>
      )}
      <div
        className={`grid md:grid-cols-2 ${
          elements.length % 4 === 0 ? "lg:grid-cols-4" : "lg:grid-cols-3"
        } gap-12 md:gap-6 lg:gap-10`}
      >
        {elements.map((el, i) => {
          const Icon = HeroIcons[el.icon];
          return (
            <div
              {...animate("fade-up", 100 * i)}
              className="align-leftover"
              key={el.name}
            >
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-900 mb-4 mx-auto">
                <Icon className="h-7 w-7 text-white" />
              </div>
              <Keyword
                center={true}
                title={el.name}
                description={el.description}
              />
              {el.child}
            </div>
          );
        })}
      </div>
    </Section>
  );
};
