import React, { FunctionComponent } from "react";
import { HeroCta } from "../cta/HeroCta";
import HeroTitle from "../typography/HeroTitle";
import LeadText from "../typography/LeadText";
import TitleTag from "../typography/TitleTag";

type ImageHero2Props = {
  tag: string;
  title: JSX.Element;
  description: string;
  image: string;
};

const ImageHero2: FunctionComponent<ImageHero2Props> = ({
  tag,
  title,
  description,
  image,
}) => {
  return (
    <div className="w-full bg-gray-100">
      <section className="relative">
        <div className="py-12 md:py-24">
          <div className="relative text-center container mx-auto z-10 px-5 lg:px-12">
            <TitleTag>{tag}</TitleTag>
            <HeroTitle>{title}</HeroTitle>
            {description && (
              <div className="mt-6 lg:max-w-xl md:mx-auto">
                <LeadText>{description}</LeadText>
              </div>
            )}
            <HeroCta center={true} />
          </div>
        </div>

        <div className="relative">
          <div className="absolute inset-0 flex flex-col" aria-hidden="true">
            <div className="flex-1" />
            <div className="flex-1 w-full bg-white" />
          </div>
          <div className="container mx-auto px-5 sm:px-6 lg:px-12">
            <img
              className="z-10 h-72 lg:h-[600px] w-full object-cover relative rounded-lg shadow-lg"
              src={image}
            />
          </div>
        </div>

        <svg className="hidden md:block absolute h-2/3 w-1/6 left:0 bottom-24 opacity-70">
            <rect width="100%" height="100%" fill="url(#squares-pattern)" />
          </svg>
        <svg className="hidden md:block absolute h-3/6 w-1/6 -top-12 right-0 text-gray-200 opacity-70">
          <defs>
            <pattern
              id="squares-pattern"
              x="0"
              y="0"
              width="40"
              height="40"
              patternUnits="userSpaceOnUse"
            >
              <path
                fill="currentColor"
                d="M6 18h12V6H6v12zM4 4h16v16H4V4z"
              ></path>
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#squares-pattern)" />
        </svg>
      </section>
    </div>
  );
};

export default ImageHero2;
