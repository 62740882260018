import React, { FunctionComponent } from "react";
import { Pattern } from "../Pattern";
import Section from "../layout/Section";
import { TitleHeading } from "../typography/TitleHeading";
import BodyText from "../typography/BodyText";
import { useAnimation } from "../../hooks/useAnimate";

type GridBlockProps = {
  tag: string;
  title: JSX.Element | string;
  description?: JSX.Element | string;
  blocks: React.ReactNode[];
  lg3?: boolean;
  theme?: "dark" | "white" | "gray";
  noMargin?: "no-margin" | "top" | "bottom";
};

const GridBlock: FunctionComponent<GridBlockProps> = ({
  tag,
  title,
  description,
  blocks,
  lg3 = true,
  theme = "white",
  noMargin,
}) => {
  const { id, animate } = useAnimation();

  return (
    <Section theme={theme} noMargin={noMargin} id={id}>
      <div {...animate("fade-down")}>
        <TitleHeading
          tag={tag}
          title={title}
          theme={theme}
          center={false}
          bgTag={true}
        />
        {description && (
          <BodyText className="max-w-4xl" mb={0}>
            {description}
          </BodyText>
        )}
      </div>
      <div
        className={`w-full grid grid-cols-1 md:grid-cols-2 ${
          lg3 ? "lg:grid-cols-3" : ""
        } gap-1 mt-12`}
      >
        {blocks.map((block, i) => (
          <div
            key={`block${i}`}
            {...animate(i % 2 === 0 ? "fade-right" : "fade-left", 100 * i)}
            className={`${
              theme === "dark"
                ? "bg-gray-800 hover:bg-gray-700"
                : theme === "white"
                ? "bg-gray-100 hover:bg-gray-200"
                : "bg-gray-200 hover:bg-gray-300"
            } p-6 transition-colors duration-200`}
          >
            {block}
          </div>
        ))}
      </div>
      <Pattern
        type="square"
        className={`hidden lg:block absolute h-1/3 w-1/6 top-0 right-0 ${
          theme === "dark" ? "text-gray-800" : "text-gray-200 opacity-70"
        }`}
      ></Pattern>
    </Section>
  );
};

export default GridBlock;
