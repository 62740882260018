import * as React from "react";
import { graphql } from "gatsby";
import { useTranslation, Trans, Link } from "gatsby-plugin-react-i18next";
import PageLayout from "../components/page-layout/PageLayout";
import Title from "../components/typography/Title";
import BodyText from "../components/typography/BodyText";
import SEO from "../components/SEO";
import Section from "../components/layout/Section";
import { FeaturesGrid } from "../components/features-grid/FeaturesGrid";
import { Pattern } from "../components/Pattern";
import { PlusIcon } from "@heroicons/react/solid";
import { MenuAlt4Icon } from "@heroicons/react/outline";
import ImageHero2 from "../components/hero/ImageHero2";
import { JoinHeroCta } from "../components/cta/JoinHeroCta";
import TextSection from "../components/text/TextSection";
import GridBlock from "../components/grid-block/GridBlock";
import Keyword from "../components/typography/Keyword";
import { useServiceTree } from "../hooks/useServiceTree";
import { useI18nLink, useServiceI18nLink } from "../hooks/useI18nLink";

// @ts-ignore
import teamImg from "../assets/images/team.jpg";
import Experts from "../components/sections/Experts";
import { TitleHeading } from "../components/typography/TitleHeading";
import { useAnimation } from "../hooks/useAnimate";

const AboutUsPage = ({
  data: {
    allExpertsJson: { nodes: experts },
  },
}) => {
  const { t } = useTranslation();
  const services = useServiceTree();
  const { serviceLink } = useServiceI18nLink();
  const i18nLink = useI18nLink();
  const { id, animate } = useAnimation();

  const values: Array<{
    name: string;
    description: string;
    icon: string;
  }> = t("about:values.list", { returnObjects: true });

  const recipe: Array<{
    name: string;
    description: string;
    icon: string;
  }> = t("about:recipe.list", { returnObjects: true });

  const more = [
    {
      title: t("about:more.services"),
      links: services.map((service) => ({
        name: t(`services:${service.id}.name`),
        to: serviceLink(service),
      })),
    },
    {
      title: t("about:more.careers"),
      links: [
        {
          name: t("about:more.workWithUs"),
          to: i18nLink("/lavora-con-noi"),
        },
        {
          name: t("about:more.seeJobPositions"),
          to: i18nLink(
            "/lavora-con-noi",
            undefined,
            undefined,
            "job-positions"
          ),
        },
      ],
    },
  ];

  return (
    <PageLayout pageName="about" headerBg="gray-100">
      <SEO
        titleI18nKey={"about:meta.title"}
        descriptionI18nKey={"about:meta.description"}
        image={teamImg}
        datePublished={new Date("2021-03-01T08:00:00Z")}
      />

      <ImageHero2
        tag={t("about:hero.tag")}
        title={
          <Trans
            i18nKey="about:hero.title"
            components={{ ol: <span className="text-ol-gradient" /> }}
          />
        }
        description={t("about:hero.content")}
        image={teamImg}
      />

      {/* WHO WE ARE */}
      <TextSection
        tag={t("about:textSection.tag")}
        title={t("about:textSection.title")}
        text={<Trans i18nKey="about:textSection.content" />}
      />

      {/* VALUES */}
      <FeaturesGrid
        tag={t("about:values.tag")}
        title={t("about:values.title")}
        elements={values}
        theme="gray"
      />

      {/* OUR WAY */}
      <Section theme="dark" id={id}>
        <div className="lg:flex lg:px-7 justify-center">
          <ul
            role="list"
            className="w-full lg:w-1/2 flex flex-col mr-7 justify-center"
          >
            {recipe.map((step, index) => (
              <li
                {...animate("fade-left", 100 * index)}
                key={`ow-${index}`}
                className="flex flex-col items-center"
              >
                <Title
                  type="h4"
                  dark
                  large={index !== recipe.length - 1}
                  xlarge={index === recipe.length - 1}
                  className={
                    index === recipe.length - 1
                      ? "text-ol-gradient text-center"
                      : "text-center"
                  }
                >
                  {step.name}
                </Title>
                {index === recipe.length - 2 ? (
                  <MenuAlt4Icon className="h-12 w-12 text-primary" />
                ) : index !== recipe.length - 1 ? (
                  <PlusIcon className="h-12 w-12 text-primary" />
                ) : undefined}
              </li>
            ))}
          </ul>

          <div className="w-full text-left lg:w-1/2 mt-24 lg:mt-0">
            <div {...animate("fade-down")}>
              <TitleHeading
                theme="dark"
                tag={t("about:recipe.tag")}
                title={t("about:recipe.title")}
              />
            </div>
            <div {...animate("fade-up")}>
              <BodyText dark={true} className="text-justify mt-6">
                <Trans i18nKey="about:recipe.description" />
              </BodyText>
              <div className="text-left mt-6">
                <JoinHeroCta smCenter={false} text={t("about:recipe.cta")} />
              </div>
            </div>
          </div>
        </div>
        <Pattern
          type="square"
          className="hidden lg:block absolute h-1/6 w-1/3 2xl:h-1/3 2xl:w-1/6 bottom-0 left-0 text-gray-700 opacity-60"
        ></Pattern>
      </Section>

      {/* MORE ABOUT US */}
      <GridBlock
        theme="gray"
        tag={t(`about:more.tag`)}
        title={t(`about:more.title`)}
        lg3={false}
        blocks={more.map((m) => (
          <div className="p-6">
            <Keyword title={m.title} />
            <ul className="mt-2 space-y-2">
              {m.links.map((l) => (
                <Link to={l.to} key={`mau-${l.name}`}>
                  <li className="text-gray-700 hover:text-gray-900 hover:underline">
                    {l.name}
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        ))}
      />

      {/* TEAM */}
      <Experts
        tag={t("about:team.tag")}
        title={t("about:team.title")}
        experts={experts}
      />
    </PageLayout>
  );
};

export default AboutUsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allExpertsJson(sort: { fields: order }) {
      nodes {
        id
        linkedinUrl
        name
        role
        picture
      }
    }
  }
`;
