import * as React from "react";
import { useAnimation } from "../../hooks/useAnimate";
import Section from "../layout/Section";
import BodyText from "../typography/BodyText";
import Title from "../typography/Title";
import { TitleHeading } from "../typography/TitleHeading";
import TitleTag from "../typography/TitleTag";

interface ExpertsProps {
  tag: string;
  title: string;
  description?: string;
  experts: {
    name: string;
    picture: string;
    role: string;
    linkedinUrl: string;
  }[];
}

const Experts: React.FunctionComponent<ExpertsProps> = ({
  tag,
  title,
  description,
  experts,
}) => {
  const { id, animate } = useAnimation();

  return (
    <Section id={id}>
      <div {...animate("fade-down")}>
        <TitleHeading center bgTag tag={tag} title={title} />
        {description && (
          <BodyText className="text-center max-w-4xl m-auto">
            {description}
          </BodyText>
        )}
      </div>
      <ul
        role="list"
        className={`grid grid-cols-1 md:grid-cols-2 ${
          experts.length > 2 ? "lg:grid-cols-3" : "lg:mx-auto lg:w-2/3"
        } gap-x-12 gap-y-8 mt-16`}
      >
        {experts.map((expert, i) => (
          <li {...animate("fade-up", 100 * i)} key={expert.name}>
            <img
              className="object-cover shadow-lg rounded-lg w-full h-96"
              src={
                require(`../../assets/images/experts/${expert.picture}`).default
              }
              alt={expert.name}
            />
            <div className="mt-4 px-4 flex items-center justify-between">
              <div>
                <TitleTag>{expert.role}</TitleTag>
                <Title small>{expert.name}</Title>
              </div>

              <a
                href={expert.linkedinUrl}
                target="_blank"
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">LinkedIn</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </div>
          </li>
        ))}
      </ul>
    </Section>
  );
};

export default Experts;
