import React, { FunctionComponent } from "react";
import { useAnimation } from "../../hooks/useAnimate";
import Section from "../layout/Section";
import BodyText from "../typography/BodyText";
import { TitleHeading } from "../typography/TitleHeading";

type TextSectionProps = {
  tag: string;
  title: JSX.Element | string;
  text?: string | React.ReactNode;
};

const TextSection: FunctionComponent<TextSectionProps> = ({
  tag,
  title,
  text,
  children,
}) => {
  const { id, animate } = useAnimation();

  return (
    <Section className="lg:max-w-4xl" id={id}>
      <div {...animate("fade-down", undefined, 120)}>
        <TitleHeading tag={tag} title={title} />
        <BodyText className="text-justify mt-6" mb="6">
          {text}
        </BodyText>
        {children}
      </div>
    </Section>
  );
};

export default TextSection;
