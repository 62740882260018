import React from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { useI18nLink } from "../../hooks/useI18nLink";

interface JoinHeroCtaProps {
  smCenter?: boolean;
  text?: string;
}

export const JoinHeroCta: React.FunctionComponent<JoinHeroCtaProps> = ({
  smCenter = true,
  text,
}) => {
  const { t } = useTranslation();
  const i18nLink = useI18nLink();

  return (
    <div className="block sm:inline-block">
      <Link
        to={i18nLink("/lavora-con-noi/") + "#job-positions"}
        className={`w-full md:w-auto ${
          smCenter ? "m-auto" : ""
        } flex items-center justify-center px-8 py-3 text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-3 md:text-lg md:px-10`}
      >
        {text || t("join:hero.cta.jobPositions")}
      </Link>
    </div>
  );
};
